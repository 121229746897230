/*
 * @Author: fengyanjing
 * @Date: 2020-06-02 14:05:28
 * @Description:实力瘦创始股东团队销售折扣明细页面
 * @FilePath: /frontend/src/pages/rebate/shilishou/share-holders/team-purch/index.js
 */
import CompRebateLayoutOne from "@/components/rebate/comp-rebate-layout-one/index.vue";
import rebateMixin from "@/mixins/shilishou-rebate";
import { getShareHoldersTeamPurch } from "@/service/rebate/shilishouRebate";
import RebateTable from "@/components/rebate/rebate-table/index.vue";

export default {
  name: "share-holders-team-purch",
  mixins: [rebateMixin],
  components: {
    CompRebateLayoutOne,
    RebateTable,
  },

  data() {
    return {
      // 本人配置列
      selfColumns: [
        {
          label: "姓名",
          key: "name",
        },
        {
          label: "团购金额",
          isRmb: true,
          key: "teamTotalSale",
        },
        {
          label: "销售折扣",
          isRmb: true,
          key: "teamDiscount",
        },
        {
          label: "推荐奖",
          isRmb: true,
          isNegative: true,
          key: "recommendAward",
        },
      ],
      //  创始人表格配置列
      founderColumns: [
        {
          label: "创始人",
          key: "name",
        },
        {
          label: "团购金额",
          isRmb: true,
          key: "teamTotalSale",
        },
        {
          label: "销售折扣",
          isRmb: true,
          key: "teamDiscount",
        },
        {
          label: "推荐奖",
          isRmb: true,
          isNegative: true,
          key: "recommendAward",
        },
      ],
      //  合伙人表格配置列
      partnerColumns: [
        {
          label: "合伙人",
          key: "name",
        },
        {
          label: "团购金额",
          isRmb: true,
          key: "teamTotalSale",
        },
        {
          label: "销售折扣",
          isRmb: true,
          key: "teamDiscount",
        },
        {
          label: "推荐奖",
          isRmb: true,
          isNegative: true,
          key: "recommendAward",
        },
      ],
    };
  },

  computed: {
    //  代理本人团队团购页面
    selfDatasource() {
      const { rebateInfo, userInfo } = this;
      if (rebateInfo) {
        const { teamTotalSale, teamDiscount, recommendAward } = rebateInfo;
        const { name, id: userId } = userInfo || {};
        return [{ teamTotalSale, teamDiscount, recommendAward, name, userId }];
      } else {
        return [];
      }
    },
    //  创始人团队团购页面
    founderDatasource() {
      const { rebateInfo } = this;
      return rebateInfo.uniteList || [];
    },
    //合伙人团队团购明细
    partnerDatasource() {
      const { rebateInfo } = this;
      return rebateInfo.strategyList || [];
    },
    //  团队总人数
    account() {
      const { founderDatasource, partnerDatasource } = this;
      return founderDatasource.length + partnerDatasource.length;
    },
  },

  methods: {
    //  初始化页面
    init() {
      const { startTime, endTime, agentId } = this;
      getShareHoldersTeamPurch({
        startTime,
        endTime,
        userId: agentId,
      }).then((data) => {
        this.rebateInfo = data;
      });
    },

    showDetail(row) {
      this.$router.push({
        name: "shilishou-team-purch-detail",
        query: { ...this.$route.query, agentId: row.userId },
      });
    },
  },
};
